var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: _vm.pageTitle } },
    [
      _c("vx-card", [
        _vm.isEditForm
          ? _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Credit Id")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "id", $$v)
                      },
                      expression: "form.id",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Amount*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|decimal",
                    expression: "'required|decimal'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  disabled: _vm.readOnly,
                  type: "number",
                  min: "0",
                  name: "Amount",
                },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", _vm._n($$v))
                  },
                  expression: "form.amount",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Amount"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Currency*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  value: _vm.selectedCurrency,
                  disabled: _vm.readOnly,
                  name: "Currency",
                  options: _vm.currencyOptions,
                },
                on: { input: _vm.setSelectedCurrency },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Currency"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Credit Reference")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:50",
                    expression: "'max:50'",
                  },
                ],
                staticClass: "w-full",
                attrs: { disabled: _vm.readOnly, name: "Credit Reference" },
                model: {
                  value: _vm.form.creditReference,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "creditReference", $$v)
                  },
                  expression: "form.creditReference",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Credit Reference"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Notes")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:500",
                    expression: "'max:500'",
                  },
                ],
                staticClass: "w-full",
                attrs: {
                  disabled: _vm.readOnly,
                  name: "Message",
                  counter: "500",
                  maxlength: "500",
                  rows: "4",
                },
                model: {
                  value: _vm.form.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "notes", $$v)
                  },
                  expression: "form.notes",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Notes"))),
              ]),
            ],
            1
          ),
        ]),
        _vm.isEditForm
          ? _c("div", { staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                _c("span", [_vm._v("Is Active")]),
              ]),
              _c(
                "div",
                { staticClass: "vx-col sm:w-4/5 w-full ml-auto" },
                [
                  _c("vs-switch", {
                    attrs: { disabled: _vm.readOnly },
                    model: {
                      value: _vm.form.isActive,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isActive", $$v)
                      },
                      expression: "form.isActive",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        !_vm.readOnly
          ? _c("div", { staticClass: "lg:float-left mt-4" }, [
              _c("span", { staticClass: "text-sm text-danger" }, [
                _vm._v("*Required Field"),
              ]),
            ])
          : _vm._e(),
        !_vm.readOnly
          ? _c(
              "div",
              { staticClass: "flex flex-wrap justify-end" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4",
                    attrs: { type: "border", color: "danger" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v("Cancel")]
                ),
                _vm.userHasPermission("company_settings_edit")
                  ? _c("vs-button", { on: { click: _vm.submitCreate } }, [
                      _vm._v("Save"),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.readOnly
          ? _c(
              "div",
              { staticClass: "flex flex-wrap justify-end" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4",
                    attrs: { type: "border", color: "success" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v("Credit Limit List")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }